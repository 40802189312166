<template>
  <div class="align-center">
    <v-select
      v-model="computedAggType"
      class="aggregators"
      :items="aggregators"
      item-text="text"
      item-value="id"
      :label="$t('t.Aggregators')"
      :hide-details="true"
    />
    <component
      class="mt-8"
      :is="computedAggType"
      :document.sync="computedAgg"
      :readonly="readonly"
      :target-doc-ty="targetDocTy"
    />
  </div>
</template>

<script>
export default {
  components: {
    All: () => import('./all'),
    Any: () => { },
    Avg: () => import('./avg'),
    Count: () => { },
    // List: () => import('./list'),
    Max: () => import('./min-max'),
    Min: () => import('./min-max'),
    Sum: () => import('./sum')
  },
  computed: {
    computedAgg: {
      get () { return this.dataAgg },
      set (v) {
        this.dataAgg = v
        this.dataDocumentEmit()
      }
    },
    computedAggType: {
      get () { return this.dataAggType },
      set (v) {
        this.dataAggType = v

        this.dataAgg = null // reset agg

        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      aggregators: [
        { id: 'all', text: this.$t('t.All'), tmpl: { filter: null } },
        { id: 'any', text: this.$t('t.Any'), tmpl: {} },
        { id: 'avg', text: this.$t('t.Average'), tmpl: { col: null } },
        { id: 'count', text: this.$t('t.Count'), tmpl: {} },
        { id: 'max', text: this.$t('t.Max'), tmpl: { cmp: null, col: null } },
        { id: 'min', text: this.$t('t.Min'), tmpl: { cmp: null, col: null } },
        { id: 'sum', text: this.$t('t.Sum'), tmpl: { col: null } }
      ],
      dataAgg: null,
      dataAggType: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      const doc = Object.assign({}, this.dataAgg, { type: this.dataAggType })

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        const s = this.lodash.cloneDeep(v)
        this.dataAgg = s
        this.dataAggType = s?.type ?? 'count'

        this.dataDocumentEmit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
